import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-gilbert-arizona.png'
import image0 from "../../images/cities/scale-model-of-golfland-sunsplash-in-gilbert-arizona.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Gilbert'
            state='Arizona'
            image={image}
            lat='33.3103'
            lon='-111.7431'
            attractions={ [{"name": "Golfland Sunsplash", "vicinity": "155 W Hampton Ave, Mesa", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 33.3880567, "lng": -111.83783740000001}] }
            attractionImages={ {"Golfland Sunsplash":image0,} }
       />);
  }
}